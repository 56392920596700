import { EnvConfig } from './model/environment.model';

export const environment: EnvConfig = {
  production: true,
  TEALIUM_ACCOUNT: 'alcon',
  TEALIUM_PROFILE: 'adi-platform',
  TEALIUM_ENV: 'qa',
  OKTA_CONFIGS: {
    ADI_HOME: {
      CLIENT_ID: '0oa22l5lajjIRedet0h8',
      ISSUER_URL: 'https://q-id.myalcon.com/oauth2/aus1l144nqzprKnJx0h8',
    },
    CLINIC_CONNECT: {
      CLIENT_ID: '0oa1wirgw2adkNyTQ0h8',
      ISSUER_URL: 'https://q-id.myalcon.com/oauth2/aus1l144nqzprKnJx0h8',
    },
    INSIGHTS_CENTER: {
      CLIENT_ID: '0oa23o75zw48c9nmL0h8',
      ISSUER_URL: 'https://q-id.myalcon.com/oauth2/aus1l144nqzprKnJx0h8',
    },
  },
  API_BE_ENDPOINT: 'https://adi.tmp.qa.myalcon.com/api',
  STORE_URL: 'https://qa4.digital.myalcon.com',
  STORE_ENROLL_URL: 'https://qa4.digital.myalcon.com/alconUS/en_US?origin=home',
  CHANGE_PASSWORD: 'https://q-identity.myalcon.com/myaccount',
  ENV: 'qa',
};
